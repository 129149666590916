module.exports = {
  // App Info
  appstore_link:
    "https://install.appcenter.ms/users/flysky/apps/yvideo-downloader-lite/distribution_groups/public", // Enter App Store URL.
  playstore_link:
    "https://install.appcenter.ms/users/flysky/apps/yvideo-downloader/distribution_groups/public", // Enter Google Play Store URL.
  google_analytics_ID: "UA-159042440-1", // Enter Google Analytics ID or ""
  presskit_download_link: "", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://agalp.imedadel.me", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "YVideo Downloader",
  app_price: "Lastet version v1.0.17.3",
  app_description:
    "The Most Powerful Media Downloader ( M3U8, MPD, MP4) for your Android Device",
  app_keywords: ["automatic", "gatsbyjs", "app", "landing page"],

  // Personal Info
  your_name: "Flysky",
  your_link: "",
  your_city: "",
  email_address: "videodownloaders2020@outlook.com",
  linkedin_username: null,
  facebook_username: null,
  instagram_username: "",
  twitter_username: "",
  github_username: "",
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Download Videos & Musics",
      description:
        "YVideo Lets you download videos from sites like youtube, instagram, facebook, bilibili and new ones are being added every week as per our users request.",
      fontawesome_icon_name: "download",
    },
    {
      title: "Download 8K Videos",
      description:
        "Because its better to see things clearer. Stream or download 8K videos with YVideo and play them on your Ultra HD devices.",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "Powerfull Video Player",
      description:
        "Watch any video in HD or SD without any ad before or during the preview. Support floating video playback, zoom in and out.",
      fontawesome_icon_name: "play-circle",
    },
    {
      title: "Upto 10X Faster Downloading",
      description:
        "YVideo uses multiple network connections to accelerate the downloading speed. You can set the number of connections according to your network quality to gain maximum speed.",
      fontawesome_icon_name: "rocket",
    },
    {
      title: "Batch Downloading",
      description:
        "Make a collection of songs and videos while exploring and set them to download in just one go. Download hundreds of songs with just a click.",
      fontawesome_icon_name: "angle-double-down",
    },
    {
      title: "Quick Download Tool",
      description:
        "Saves your time. A very handy tool when you have to download but you are in a rush. No more waiting for the links to load. Just two simple steps and the download is done.",
      fontawesome_icon_name: "bolt",
    },
    {
      title: "Inbuilt Browser",
      description:
        "In app browser makes it super easy to download videos from various sites, as it detects the video or media file and shows you a download popup.",
      fontawesome_icon_name: "globe",
    },
    {
      title: "Unlimited themes",
      description:
        "Customize YVideo according to your choice. Use any preset theme or choose a color from the palette. Make YVideo your own.",
      fontawesome_icon_name: "palette",
    },
    {
      title: "Night Mode",
      description:
        "Night mode makes it easier for you to use the app in the night. Night mode is super recommended for amoled screens.",
      fontawesome_icon_name: "moon",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
